import React, { useState, useEffect, useRef } from 'react';
import * as d3 from "d3";
import axios from 'axios';
import TreeViews from './TreeViews';
import './StackedChart.css';
import LinearProgress from '@mui/material/LinearProgress';
import { element } from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';


const _ = require("lodash");  

const fillArray = (clone_ids) => {
    let aux_array = [];
    for(let i=0;i<clone_ids.length;i++){
        aux_array.push(i)
    }
    return aux_array;
};

const StackedChart = ({ data, dimensions }) => {

    const host = window.location.origin;

    const svgRef = React.useRef(null);
    const tooltipRef = useRef();

    const { width, height, margin } = dimensions;
    const svgWidth = width + margin.left + margin.right;
    const svgHeight = height + margin.top + margin.bottom;

    const [clone_id, setClone_id] = useState();
    const [sequencesInfo, setSequencesInfo] = useState([]);
    const [newickTrees, setnewickTrees] = useState([]);
    const [seqs, setSeqs] = useState([]);
    const [newStack, setNewStack] = useState(true);


    let source;

    const treeDimensions = {
        width: 200,
        height: 100,
        margin: { top: 5, right: 5, bottom: 10, left: 10 }
    };

    const convertNodeName = nodeName => `${nodeName.split('-').splice(0, 3).join(':')}-${nodeName.split('-').splice(3, 5).join(':')}`;

    const getSeqNamesImmuneDB = newick => newick.match(/(\w*\d*-\w*\d*){7}/g).map(name => convertNodeName(name));

    const getInfoOnClone = (clone_id) => {
        console.log("GOT IN GET INFO", clone_id);

        let sequences = [];

        
        if (source){
            console.log("Stoping previous request");
            source.cancel('Operation canceled by the user.');
        } 
        
        source = axios.CancelToken.source();

            axios.get(host + "/newicks/", {
            params: {
                clone_id: clone_id
            }
            })
            .then(res => {
                console.log(res.data);
                let trees = res.data;
                setnewickTrees(res.data);

                if(trees.length>0){
                    console.log("got the trees from db", trees);
        
                    let newick_trees = []
        
                    trees.forEach(n => newick_trees.push(n.newick_tree));
        
                    newick_trees.forEach(n => {const seqs = getSeqNamesImmuneDB(n); sequences = [...new Set([...sequences, ...seqs])]});
                    console.log("Got all sequences", sequences);
                    setSeqs(sequences);
        
                    axios.post(host + "/seq/", {sequences}, {cancelToken: source.token})
                    .then(res => {
                        console.log("Got post request")
                        console.log(res.data);
                        setNewStack(false);
                        setSequencesInfo(res.data);
                    })
                    .catch(function (error) {
                        if (axios.isCancel(error)) {
                            console.log('Request canceled', error.message);
                        } 
                        else {
                            console.log(error);
                        }
                    })
                }
            })
            .catch(function (error) {
            console.log(error);
            })
    }
   
    useEffect(() => {
         

        let clone_ids = [...new Set(data.map(a => a.clone_id))];
        //console.log(clone_ids);

        let timepoints = [...new Set(data.map(a => a.timepoint))];
        timepoints.sort();

        let auxData = new Map();

        data.forEach(element => {
            //verifies if cloneid exists in map
            if(!auxData.has(element.clone_id)){//if not add to map
                auxData.set(element.clone_id, {timepoints: [element.timepoint], total_cnts: [element.total_cnt]});
            }
            else{//if it already exists
                if(auxData.get(element.clone_id).timepoints.indexOf(element.timepoint) === -1){//if timepoint for this data object doesn't exist in map for this cloneid add timepoint
                    auxData.get(element.clone_id).timepoints.push(element.timepoint);
                    auxData.get(element.clone_id).total_cnts.push(element.total_cnt);
                }
                else{//if timepoint already exists sum total_count to the total_cnt in the same index as the timepoint 
                    let index = auxData.get(element.clone_id).timepoints.indexOf(element.timepoint);
                    let sum = element.total_cnt + auxData.get(element.clone_id).total_cnts[index];
                    auxData.get(element.clone_id).total_cnts.splice(index, 1, sum);
                }

            }
        });

        //verify if each cloneid element has all timepoints, if not 
       
        auxData.forEach(function(value, key) {

            let elem_timepoints = value.timepoints;
            
            timepoints.forEach(element => {
                if(elem_timepoints.indexOf(element) === -1){
                    value.timepoints.push(element);
                    value.total_cnts.push(0);
                }
            })
        });
       

        //build an object array from auxData
        let newData = []; 

        auxData.forEach(function(value, key) {
            value.timepoints.forEach(t => {
                var newObj = {};
                newObj.clone_id = key;
                newObj.total_cnt = value.total_cnts[value.timepoints.indexOf(t)];
                newObj.timepoint = t;
                newData.push(newObj);
            })
            
        });

        data = newData;


        //order clone_ids

        data.sort(function(x, y){
            return d3.ascending(x.clone_id, y.clone_id);
        })

        data.sort(function(x, y){
            return d3.ascending(x.timepoint, y.timepoint);
        })

    
        // group the data: one array for each value of the X axis.
        const sumstat = d3.group(data, d => d.timepoint);


        // Stack the data: each group will be represented on top of each other

        const mygroup = fillArray(clone_ids);
        const stackedData = d3.stack()
            .keys(clone_ids)
            .value(function(d, key){
                if(clone_ids.indexOf(key)<d[1].length && clone_ids.indexOf(key)!=-1)
                    return d[1][clone_ids.indexOf(key)].total_cnt
            })
            (sumstat)


        const svgEl = d3.select(svgRef.current);
        svgEl.selectAll("*").remove(); // Clear svg content before adding new elements 
        const svg = svgEl;

        //calculate max height of stacked area chart
        let last_stack = stackedData[stackedData.length-1];

        let stacks_height = [];

        for(let t=0; t<timepoints.length; t++){
            stacks_height[t] = last_stack[t][1]
        }

        let max_height = Math.max.apply(null, stacks_height);


        // Add X axis 
        const scaleX = d3.scaleLinear()
            .domain(d3.extent(data, function(d) { return d.timepoint; }))
            .range([ 0, width ]);
        svg.append("g")
            .attr("transform", `translate(50, ${height})`)
            .call(d3.axisBottom(scaleX).ticks(timepoints.length-1));

        // Add Y axis
        const scaleY = d3.scaleLinear()
            .domain([0, max_height] )
            .range([height, 50]);
        const yAxis = d3.axisLeft(scaleY);
        svg.append("g")
            .attr("transform", "translate(50,0)") 
            .call(yAxis)

       

        // color palette
        const colorArray = ['#00429d', '#1e499a', '#2c4f96', '#365693', '#3e5d90', '#46638c', '#4c6a89', '#527186', '#577882', '#5c7e7f', '#61857c', '#668c79', '#6b9376', '#709a73', '#75a06f', '#7aa76c', '#7fae69', '#85b466', '#8bbb63', '#92c160', '#99c85e', '#a1ce5b', '#a9d459', '#b2da57', '#bce055', '#c7e654', '#d3eb54', '#e0f055', '#eff456', '#fff85a'];

        let total_cnts = [...new Set(data.map(a => a.total_cnt))];
        let max = Math.max.apply(null, total_cnts);

        function get_larger_cnt(el){
            let counts = [];

            for(let t=0; t<timepoints.length; t++){
                counts.push(el[t][1]-el[t][0]);
            } 

            
            return Math.max.apply(null, counts);
        }

        function attributeColor(el) {

            let larger_cnt = get_larger_cnt(el);
            let percentage = larger_cnt/max;
            let color_index_float = d3.interpolate(0, 30)(percentage);
            let color_index = Math.round(color_index_float);
            let color = colorArray[color_index-1];

            return color;

        }



    // What to do when one group is hovered
    function highlight(key){
            // reduce opacity of all groups
            d3.selectAll(".myArea").style("opacity", .3)
            // expect the one that is hovered
            d3.select(".c" + key).style("opacity", 1);
            
      }


    
        /*
        ADD TOOLTIP WITH CLONE ID
        */    

        

            // Three function that change the tooltip when user hover / move / leave a cell
        const mouseover = (d) => {
            const tooltipDiv = tooltipRef.current;
            var clone_id = d.target.__data__.key;
            d3.select(tooltipDiv)
                .html("clone_id: " + clone_id)
                .style("opacity", 1)
        }
        const mousemove = (event, d) => {
            const tooltipDiv = tooltipRef.current;
            d3.select(tooltipDiv)
                .style("transform","translateY(55%)")
                .style("left",(event.pageX)+"px")
                .style("top",(event.pageY)+"px")

        }
        const mouseleave = (d) => {
            const tooltipDiv = tooltipRef.current;
            d3.select(tooltipDiv)
                .style("opacity", 0)
        }
        
        // Show the areas
        svg
            .selectAll("mylayers")
            .data(stackedData)
            .enter()
            .append("path")
                .attr("transform", "translate(50,0)") 
                .attr("class", function(d) {/* console.log(d); */return "myArea c" + d.key })
                .style("fill", function(d) { return attributeColor(d);  })
                .attr("d", d3.area()
                    .x( d => { return scaleX(d.data[0]); })
                    .y0(d => {return scaleY(d[0]); })
                    .y1(d => {return scaleY(d[1]); })
                )
                .style("cursor", "pointer")
            .on("mousedown", function(d) {
                    setClone_id(d.target.__data__.key);
                    getInfoOnClone(d.target.__data__.key);
                    setNewStack(true);
                    return highlight(d.srcElement.__data__.key);
                
            })
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)


/*         var tooltip = svg.append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("height", "100px")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px"); */
            
  

    }, [data]); // Redraw chart if data changes



    return (
        <> 
            <div className="tooltip" ref={tooltipRef} />
            <svg ref={svgRef} width={svgWidth} height={svgHeight} />
            {newStack && clone_id && (newickTrees.length>0) && (sequencesInfo.length===0 || !(sequencesInfo.every(v => seqs.includes(v.name)))) && (<div className='waitingSeqs'><p >Please hold while fetching sequences and their metadata for clone {clone_id}...</p> <LinearProgress color="primary" /></div>)}
            {newStack && clone_id && (newickTrees.length===0) && (<div className='waitingSeqs'><p >There are no trees available for clone {clone_id}</p></div>)}
            {(!newStack && clone_id && sequencesInfo.length>0 && (sequencesInfo.every(v => seqs.includes(v.name))) && newickTrees.length>0) && <TreeViews clone_id={clone_id} newickTrees={newickTrees} sequencesInfo={sequencesInfo}></TreeViews> }
        </>
    )
    
    

};

export default StackedChart;