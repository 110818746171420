import React, { useState, useEffect } from 'react';
import axios from "axios";

import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Button, Switch, CircularProgress } from '@mui/material';
import {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


import './Form.css';
import StackedChart from './StackedChart';
//import { bool } from 'prop-types';

function FilterForm() {

  const host = window.location.origin;

  const theme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        }, 
      }, 
    }, 
    typography: {
      fontFamily: [
        'Satoshi-Variable',
      ].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Satoshi-Variable';
            src: url('../fonts/Satoshi-Variable.woff2') format('woff2');
            font-weight: 300 900;
            font-display: swap;
            font-style: normal;
          }
        `,
      },
    },
    palette: {
      primary: {
        light: '#A3B18A',
        main: '#53714B',
        dark: '#283E28',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A3B18A',
        main: '#53714B',
        dark: '#283E28',
        contrastText: '#000',
      },
    },
  });

    const dimensions = {
      width: 1200,
      height: 600,
      margin: { top: 30, right: 30, bottom: 80, left: 50 }
    };

    const [filters, setFilters] = useState([]);
    const [cs_enabled, setCs_enabled] = useState(false);
    const [tc_enabled, setTc_enabled] = useState(false);
    const [mn_enabled, setMn_enabled] = useState(false);
    const [timepoints, setTimepoints] = useState([]);
    const [tissues, setTissues] = useState([]);
    const [clone_size, setClone_size] = useState([2,20]);
    const [top_clones, setTop_clones] = useState(10);
    const [subjects, setSubjects] = useState(3);
    // const [min_nodes, setMin_nodes] = useState(1);
    const [results, setResults] = useState([]);


//Get initial data of values for filters (ex: which timepoints exist) 
    useEffect(() => {
      axios.get(host + "/api/")
        .then(res => {
            setFilters(res.data);
        })
        .catch(function (error) {
          console.log(error);
        })
   
    }, []);


    const handleInputChange = e => {
        const target = e.target;
        let value = target.value;
        
        if(target.name === "timepoints"){  
          if(target.checked){ 
            setTimepoints(timepoints => [...timepoints,value]);
          }
          else if(!target.checked){
            setTimepoints(timepoints.filter(t => t!==value));
          }
        }
        if(target.name === "tissues")
        {
          if(target.checked){
            setTissues(tissues => [...tissues,value]); 
          }
          else if(!target.checked){
            setTissues(tissues.filter(t => t!==value));
          }
        }    
        if(target.name === "cs_disable"){

            if(cs_enabled === true) setCs_enabled(false);
            else if (cs_enabled === false) setCs_enabled(true);

        } 
        if(target.name === "tc_disable"){

          if(tc_enabled === true) setTc_enabled(false);
          else if (tc_enabled === false) setTc_enabled(true);

        } 
        if(target.name === "mn_disable"){

          if(mn_enabled === true) setMn_enabled(false);
          else if (mn_enabled === false) setMn_enabled(true);

        } 
        if(target.name === "clone_size"){
          setClone_size(value);
        }
        if(target.name === "top_clones"){
          setTop_clones(value);
        }

        if(target.name === "subjects"){
          setSubjects(value);
        }

       /*  if(target.name === "min_nodes"){
          setMin_nodes(value);
        } */
    };



  const handleSubmit = e => {
    e.preventDefault();

    let time_point = timepoints;
    let sample_origin = tissues;
    let c_size, t_clones/* , u_sequences */;

    if(cs_enabled) {c_size = clone_size}  
    if(tc_enabled) {t_clones = top_clones}  
    // if(mn_enabled) {u_sequences = min_nodes}


    axios.post(host + "/api/", {
      time_point, sample_origin, subjects, c_size, t_clones/* , u_sequences */
    })
    .then(res => {
      setResults(res.data);
      console.log(res.data);
    })
    .catch(function (error) {
      console.log(error);
    })
  
  };

  console.log(results);

  const handleFilterClick = e => {
    if (!e.target.className.includes('inputLabels')) return;

    e.currentTarget.childNodes.forEach(child => {
      if (child.className.includes('selected')) child.classList.remove('selected');
    })
    e.target.classList.add('selected');

    e.currentTarget.nextSibling.firstChild.childNodes.forEach(child => {
      if (child.className.includes('show')) {
        return child.classList.remove('show');
      }

      if (child.className.includes(e.target.className.match((/(?!inputLabels|selected\b)\b\w+/g)))) {
        return child.classList.add('show');
      }
    })
  }

  if(Object.keys(filters).length !== 0) {
      return (
        <ThemeProvider theme={theme}> 
        <div className='filterContainer'>
            <p>Filter the dataset</p>
          <form onSubmit={handleSubmit}>
            <div className='labelsContainer' onClick={handleFilterClick}>
              <p className='inputLabels subject selected'>Subject</p>
              <p className='inputLabels timePoint'>Time Point</p>
              <p className='inputLabels tissue'>Tissue</p>
              <p className='inputLabels cloneSize'>Clone Size</p>
              <p className='inputLabels topClones'>Top Clones</p>
              {/* <p className='inputLabels noUseqs'>No. Unique Sequences per Timepoint</p> */}
            </div>
            <FormGroup className="form-group">
              <Box className='filterBox'>

                  <Box className='inputsContainer subject show' sx={{ padding: 2}}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={subjects}
                    >
                    {filters.Subjects?.map(j => {
                      return <FormControlLabel control={<Radio name="subjects" value={j} onChange={handleInputChange}/>} label={j} />
                    })}
                    </RadioGroup>
                  </Box>

                  <Box className='inputsContainer timePoint' sx={{ padding: 2}}>
                    {filters.time_point?.map(j => {
                      return <FormControlLabel control={<Checkbox name="timepoints" id={"inlineCheckbox" + {j}} value={j} onChange={handleInputChange} />} label={j} />
                    })}
                    
                  </Box>

                  <Box className='inputsContainer tissue' sx={{ padding: 2}}>
                    {filters.sample_origin?.map(j => {
                      return <FormControlLabel control={<Checkbox name="tissues" id={"checkboxh" + {j}} value={j} onChange={handleInputChange} />} label={j} />
                    })}
                  </Box>

                  <Box className='inputsContainer cloneSize' sx={{ padding: 2}}>
                    <FormControlLabel control={<Switch
                      checked={cs_enabled}
                      onChange={handleInputChange}
                      name="cs_disable"
                      color="primary"
                        />
                      }
                      label="Enabled"
                    />
                    <Slider
                      name='clone_size'
                      onChange={handleInputChange}
                      value={clone_size}
                      valueLabelDisplay="auto"
                      min={1}
                      max={filters.Clone_size[subjects][1]}
                      disabled={!cs_enabled}
                    />
                  </Box>

                  <Box className='inputsContainer topClones' sx={{ paddingTop: 2, paddingLeft: 3, paddingRight: 3, paddingBottom: 2}}>
                    <FormControlLabel control={<Switch
                      checked={tc_enabled}
                      onChange={handleInputChange}
                      name="tc_disable"
                      color="primary"
                        />
                      }
                      label="Enabled"
                    />
                   
                    <Slider
                      name='top_clones'
                      onChange={handleInputChange}
                      value={top_clones}
                      valueLabelDisplay="auto"
                      step={10}
                      min={0}
                      max={filters.Top_clones[subjects]}
                      disabled={!tc_enabled}
                    />
                    
                    
                  </Box>

                  {/* <Box className='inputsContainer noUseqs' sx={{ padding: 2}}>
                    <FormControlLabel control={<Switch
                      checked={mn_enabled}
                      onChange={handleInputChange}
                      name="mn_disable"
                      color="primary"
                        />
                      }
                      label="Enabled"
                    />
                    <TextField
                      name='min_nodes'
                      id="outlined-number"
                      type="number"
                      value={min_nodes}
                      size="small"
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!mn_enabled}
                      sx={{ width: 90, height: 30}}
                    />
                  </Box> */}
                  
                </Box>
                <Box sx={{ width: 200, padding: 2, fontFamily: 'Satoshi-Variable' }}>
                    <Button className='submitBtn' size="large" variant="contained" type="submit">
                        Apply Filters
                    </Button>
                </Box>
          </FormGroup>
          </form>
          <div className='showFiltersContainer'>
            <div className='selectedFilter'>
              <p>Subject</p>
              {subjects && (<p>{subjects}</p>)}
            </div>
            {timepoints.length >0 && <div className='selectedFilter'>
              <p>Timepoints</p>
              {timepoints?.map(t => {return <p>{t}</p>})}
            </div>}
            {tissues.length >0 && <div className='selectedFilter'>
              <p>Tissue</p>
              {tissues?.map(t => {return <p>{t}</p>})}
            </div>}
            {cs_enabled && <div className='selectedFilter'>
              <p>Clone_size</p>
              {<p>Min: {clone_size[0]}</p>}
              {<p>Max: {clone_size[1]}</p>}
            </div>}
            {tc_enabled && <div className='selectedFilter'>
              <p>Top Clones</p>
              {<p>{top_clones}</p>}
            </div>}
            
          </div>
        </div>
        {results.length>0 && <StackedChart  data={results} dimensions={dimensions}/>}
        </ThemeProvider>
        
        
      );
    }
    else{
      return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100vw', paddingTop: 10 }}> 
          <CircularProgress sx={{[`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },}} color='primary' size={'7vh'} thickness={5}/> 
        </Box>
      </ThemeProvider>);
    }
  };

export default FilterForm;