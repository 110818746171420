import React, { useEffect, useState } from 'react';
import * as d3 from "d3";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';

import './Tree.css';
import { node } from 'prop-types';
import { pointer } from 'd3';

const Tree = ({ newick_Trees, newick, tree_type, view_options, selectedTimepoints, possibleTimepoints, view_name, sequencesInfo}) => {

    const [highlightDetails, setHighlightDetails] = useState(null);

    console.log(view_options);

    const timepoints = selectedTimepoints;
    console.log(timepoints);

    const allTimepoints = possibleTimepoints;
    console.log(allTimepoints); 

    const svgRef = React.createRef();


    newick_Trees.sort((a,b) => (a.time_point > b.time_point) ? 1 : ((b.time_point > a.time_point) ? -1 : 0))
    if(newick_Trees[newick_Trees.length-1].time_point === "all"){
        const all_el = newick_Trees.pop();
        newick_Trees.unshift(all_el);
    }
    console.log("sorted newicks", newick_Trees);


    let newicks = [];
    newick_Trees.forEach(n => newicks.push(n.newick_tree));

    const newickList = newick_Trees.some(n => n.time_point === 'all')? newicks.slice(1): newicks; 
    console.log(newick_Trees);


    function parseNewick(newick, seqInfo) {

        var ancestors = [];
        var tree = {};
        var splitNewick = newick.split(/\s*(;|\(|\)|,|:)\s*/);

        for (var i=0; i<splitNewick.length; i++) {
            var token = splitNewick[i];
            switch (token) {
                case '(': // new branchset
                    var subtree = {};
                    tree.children = [subtree];
                    ancestors.push(tree);
                    tree = subtree;
                break;
                case ',': // another branch
                    var subtree = {};
                    ancestors[ancestors.length-1].children.push(subtree);
                    tree = subtree;
                break;
                case ')': // optional name next
                    tree = ancestors.pop();
                break;
                case ':': // optional length next
                break;
                default:
                    var x = splitNewick[i-1];
                    if (x == ')' || x == '(' || x == ',') {
                        tree.name = token;
                        if((token != "") && (!token.includes("GERM"))) tree.mutations = getMutationsNode(token, seqInfo);
                    } else if (x == ':') {
                        tree.distance = parseFloat(token);
                    }
            }
        }
        return tree;
    }



 

    function getMutationsNode(node, seqInfo){

        let translated_node = convertNodeName(node);


        let sequenceObject = seqInfo.find(seq => {
            return seq.name === translated_node
        })
 

        let sequence = sequenceObject.sequence[0];
        let germline = sequenceObject.germline[0];

        let differences = [];

        for(let i=80; i<sequence.length; i++){
            if((sequence[i] !== 'N' && sequence[i] != '-') && (germline[i] !== 'N' && germline[i] != '-')){
                if(sequence[i]!=germline[i]){
                    let difference = germline[i] + i + sequence[i];
                    differences.push(difference);
                }
            }
        }

        return differences;
    }

    const averageColor = (hex1, hex2) => {
        const splitHEX = color => color.match(/.{1,2}/g);
        const leadingZero = val => (Number(val) < 10 ? '0'+val : val).toUpperCase(); 
        const convert = val => parseInt(val,16)
        const color1 = splitHEX(hex1);
        const color2 = splitHEX(hex2);
        return color1.map((component, idx) =>
          leadingZero(Math.round( (convert(component) + convert(color2[idx]) ) / 2 ).toString(16)))
        .join('');
      };



    //FUNCTION THAT ATTRIBUTES COLOR TO EACH NODE ACCORDING TO TIMEPOINT

    function atrributeColortoNode(n, type){
        let colorListNode = ["#A9D18E", "#F4B183", "#D7A3F9", "#E3D49A", "#E6D4E2", "#F2ADC1"]
        let colorListBorder = ["#548235", "#C55A11", "#7030A0", "#BDA74F", "#B7A9B4", "#D87886"]
        const node = n.data.name;
        let belongsNewick = false;
        

        if(node.includes("GERM")){
            if(type === "fill")
                return "#FFD966";
            if(type === "stroke"){
                return "#BF9000";
            }
        }

        else if(node === ''){
            if(n.children.every(child => atrributeColortoNode(child,"stroke") === "#f0f0f0")){
                return "#f0f0f0";
            }
            else return "#b8b8b8";
        }
        else{
            for(let index= 0; index<newickList.length; index++){

                const otherNewicks = newickList.slice();
                otherNewicks.splice(index, 1);

                const thisNewick = newickList[index];

                if(newickList[index].includes(node)){ 
                    belongsNewick = true;
                    if(!timepoints.includes(allTimepoints[index])){
                        if(type === "fill"){
                            return "#fff";
                        }
                        if(type === "stroke"){
                            return "#f0f0f0";
                        }
                    }
                    else if(otherNewicks.some(n => n.includes(node))){
                        let otherIndex = newickList.findIndex((n,idx)=> n.includes(node) && idx!=index);
                        if(type === "fill")
                            return averageColor(colorListNode[index], colorListNode[otherIndex]);
                        if(type === "stroke")
                            return averageColor(colorListBorder[index], colorListBorder[otherIndex]);
                    }
                    else{
                        if(type === "fill")
                            return colorListNode[index];
                        if(type === "stroke")
                            return colorListBorder[index];
                    }
                }
                    
            }
            if(!belongsNewick){
                if(type === "fill")
                    return "#3275a8";
                if(type === "stroke")
                    return "#113047";
            }
        }
            return "#000";
    }

    function atrributeColortoBranch(node){


        if(node.data){
            if(node.data.name === ''){
                if((atrributeColortoBranch(node.parent)==="#f0f0f0") && (node.children.every((child) => atrributeColortoNode(child,"stroke")==="#f0f0f0"))){
                    console.log(node);
                    return "#f0f0f0";
                }
                else
                    return "#b8b8b8";
            }
    
            for(let i=0; i < newickList.length; i++){
                
                if(newickList[i].includes(node.data.name) && !timepoints.includes(allTimepoints[i]))
                    return "#f0f0f0";
                else{
                    return "#b8b8b8";
                }
            }
        }

        return "#fff";
                
    }


    //Function that calculates coordinates for pattern lines on nodes

    function getLineCircleCoords(node, coordinate, tree){

        //newick_Trees.forEach(n => {newickList.forEach((nl, index) => {if(n.newick_tree === nl) console.log("newick list index ", index, "Belongs to timepoint ", n.time_point)})});
        //newick_Trees.forEach(n => {if(n.newick_tree.includes(node.data.name)) console.log(node.data.name, "Belongs to timepoint ", n.time_point)});
        


        let coord = 0;
        let x, y;

        if(tree==="regular"){
            x = node.x;
            y = node.y;
        }


        if(tree==="radial"){
            if(node.data.name === '' || node.data.name.includes("GERM")){
                return coord;
            }
            else{
                let valueLine = ( view_options[2] ? calculateNodeSize(node.data.name) : 8);

                switch(coordinate){
                    case "x1":
                        coord = 0
                        break;
                    case "x2":
                        coord = 0
                        break;
                    case "y1":
                        coord = -valueLine 
                        break;
                    case "y2":
                        coord = valueLine
                        break;
                }
            }
           
        }

        else if(tree==="regular"){

            if(node.data.name === '' || node.data.name.includes("GERM")){
                return coord;
            }
            else if(newickList[0].includes(node.data.name)){
                let valueLine = 6;
                if(view_options[2]){
                    valueLine = 8+((calculateNodeSize(node.data.name)-8)/4)-2;
                    //console.log(valueLine);
                }
    
                switch(coordinate){
                    case "x1":
                        coord = x - valueLine
                        break;
                    case "x2":
                        coord = x + valueLine
                        break;
                    case "y1":
                        coord = y - valueLine 
                        break;
                    case "y2":
                        coord = y + valueLine 
                        break;
                }
            }
            if(newickList[1]){if(newickList[1].includes(node.data.name)){
                let valueLine = 8;
                if(view_options[2]){
                    valueLine = 8+((calculateNodeSize(node.data.name)-8)/4);
                }
                switch(coordinate){
                    case "x1":
                        coord = x - valueLine
                        break;
                    case "x2":
                        coord = x + valueLine
                        break;
                    case "y1":
                        coord = y
                        break;
                    case "y2":
                        coord = y
                        break;
                }
            }}
            if(newickList[2]){
                console.log("there is a newick 4");
                if(newickList[2].includes(node.data.name)) { 
                console.log("node got in", node.data.name)
                
                let valueLine = ( view_options[2] ? 8+((calculateNodeSize(node.data.name)-8)/4) : 8);

                switch(coordinate){
                    case "x1":
                        coord = x
                        break;
                    case "x2":
                        coord = x
                        break;
                    case "y1":
                        coord = y - valueLine
                        break;
                    case "y2":
                        coord = y + valueLine 
                        break;
                }

            }}
        }
        

        return coord;
        
    }

    const setNodeDetailsView = node => {

        console.log("SET NODE DETAILS");

        const nodeData = node.srcElement.__data__.data;

        let translated_node = convertNodeName(nodeData.name);

        let sequenceObject = sequencesInfo.find(seq => {
            return seq.name === translated_node
        })

        if(sequenceObject.name === "M03592:154:000000000-JCY9V:1:2106:5243:17893"){
            let seqe = {...sequenceObject.metadata};
            console.log("NODE NOT FROM TREE", seqe);
        }


        const selectedMetadata = view_options[3];

        const newMetadata = sequenceObject.metadata.filter(m => {return selectedMetadata.includes(m[0]);});

        sequenceObject.metadata = newMetadata;

        // if(sequenceObject.metadata.length===0 || selectedMetadata.length===0) sequenceObject.metadata=["No selected metadata"];

        setHighlightDetails(sequenceObject);
    }

    const setBranchDetailsView = branch => {

        const branchData = branch.srcElement.__data__

        const sourceNode = branchData.source
        const targetNode = branchData.target

        let nMutations, muts;
        if(targetNode.data.mutations){
            nMutations = targetNode.data.mutations.length;
            muts = targetNode.data.mutations;
        }
        else{
            nMutations = "Not possible to calculate mutations";
            muts = ["Not possible to calculate mutations"];
        }

        const branchInfo = {
            source: sourceNode.data.name,
            target: targetNode.data.name,
            numberMutations: nMutations,
            mutations: muts
        }

        //console.log("branch info", branchInfo);
        setHighlightDetails(branchInfo);
    }

    const calculateNodeSize = node => {

        const translated_node = convertNodeName(node);

        const sequenceObject = sequencesInfo.find(seq => {
            return seq.name === translated_node
        })

        const seqSize = sequenceObject.size;

        return 8 + (4*Math.floor(seqSize/10));


    }

    const getLineRotationAngle = (d) => {
        if(newickList[0].includes(d.data.name)) return 60;
        else if(newickList[1]){if(newickList[1].includes(d.data.name)) return 180;} 
        else if(newickList[2]){if(newickList[2].includes(d.data.name)) return 90};

        return 0;
    }

    function radialTreeDiagram(root, svg, height, width, seqInfo){

        let diameter = height * 0.8
        let radius = diameter / 2;

        let treeLayout = d3.cluster()
            .size([2*Math.PI, radius])
            .separation(function(a, b) { return (a.parent == b.parent ? 2 : 4) });

        //console.log(treeLayout.nodes);

       /*  let treeLayout = d3.tree()
            .size([2*Math.PI, radius]) 
            .separation(function(a, b) { if(a.depth<2){return a.depth} else{return (a.parent == b.parent ? 2 : 3)}  }); */


        let treeData = treeLayout(root);

        let nodes = treeData.descendants();
        let links = treeData.links();
    
        let treeGroup = svg.append('g')
                        .attr('transform', "translate("+(width/1.75)+","+(height/2)+")");

        let link = treeGroup.selectAll(".link")
                    .data(links)
                    .join("path")
                    .attr("class", "link")
                    .attr("fill", "none")
                    .attr("stroke-width", d => {if(view_options[0] && d.target.data.mutations){ return d.target.data.mutations.length;}/*  return d.data.mutations.length; */ else return 1.5})
                    .attr("stroke", d => {return atrributeColortoBranch(d.target)})
                    .attr("d", d3.linkRadial()
                        .angle(d => d.x)
                        .radius(d => d.y))
                    .on("click", d => {setBranchDetailsView(d); 
                                        //console.log(d);
                                       d3.selectAll(".click-circle-" + view_name.split(" ").join("")).remove();
                                       d3.selectAll(".branchSelect-" + view_name.split(" ").join("")).style("stroke", d => {return atrributeColortoBranch(d.target)}).style("stroke-dasharray", ("")); 
                                       d3.select(d.target).classed("branchSelect-" + view_name.split(" ").join(""), true); 
                                       d.target.style.stroke = "rgb(0,0,0)";
                                       d.target.style.strokeDasharray ="5, 3"})
                    .style("cursor", "pointer")
                    .append("text")
                    .text(function(d) {
                        //console.log(Math.cos(d.target.x));
                        if(d.target.data.mutations){
                            return d.target.data.mutations.length;
                        }
                        else
                            return "";
                    });

        
      /*   if(view_options[1]){
            console.log("append text");
            svg.selectAll('text.link')
            .data(links)
            .enter().append("text")
            .style("font", "normal 20px Arial")
            .attr("transform", function(d){console.log(d);console.log(Math.cos(d.target.x-Math.PI/2));
                if(d.target.x>1) return `translate(${height/2+50}, ${height/2})`+`translate(${(d.target.y-40)*Math.sin(d.target.x-Math.PI/2)}, ${(d.target.y-40)*Math.cos(d.target.x-Math.PI/2)})`+`rotate(${(d.target.x * 180 / Math.PI - 90)})`;
                else return `translate(${height/2+50}, ${height/2})`+`translate(${(d.target.y-40)*Math.cos(d.target.x-Math.PI/2)}, ${(d.target.y-40)*Math.sin(d.target.x-Math.PI/2)})`+`rotate(${(d.target.x * 180 / Math.PI - 90)})`;
            }) 
            .text(function(d) {
                console.log(d);
                if(d.target.data.mutations){
                    console.log(d.target.data.mutations.length);
                    return d.target.data.mutations.length;
                }
                else
                    return "";
            });
        } */
          
        let node = treeGroup
                    .selectAll(".node")
                    .data(nodes)
                    .join("g")
                    .attr("class", "node")
                    .attr("class", d => {return "id"+d.id})
                    .attr("transform", function(d){
                        return `rotate(${(d.x * 180 / Math.PI - 90)})` 
                            + `translate(${d.y}, 0)`;
                    });



        node.append("path")
            .style("fill", function(d) {return atrributeColortoNode(d, "fill") })
            .style("stroke", function(d) {return atrributeColortoNode(d, "stroke") })
            .style("stroke-width", 2)
            .attr("d", d3.symbol()
                            .size(d => {if(d.data.name==='') return 5*15; else {if(view_options[2] && !d.data.name.includes('GERM')){return calculateNodeSize(d.data.name)*22;} else return 10*22;}})
                            .type(function(d) { if(d.data.name.includes("GERM")) { return d3.symbolSquare; } 
                                            else { return d3.symbolCircle;}}))
            .on("click", d => {setNodeDetailsView(d);
                //console.log(d);
                d3.selectAll(".branchSelect-" + view_name.split(" ").join("")).style("stroke", d => {return atrributeColortoBranch(d.target)}).style("stroke-dasharray", ("")); 
                d3.selectAll(".click-circle-" + view_name.split(" ").join("")).remove();
                //console.log(node);
                    d3.selectAll(".id" + d.target.__data__.id).append("circle")
                    .attr("r", 30)
                    .style("stroke", "#000")
                    .style("fill", "none")
                    .attr('class', "click-circle-"+view_name.split(" ").join(""))
               }) 
            .style("cursor", "pointer");

        let angle = 0;

        node.append('line')
            .attr("x1", d => {return getLineCircleCoords(d, "x1", "radial")})
            .attr("y1", d => {return getLineCircleCoords(d, "y1", "radial")})
            .attr("x2", d => {return getLineCircleCoords(d, "x2", "radial")})
            .attr("y2", d => {return getLineCircleCoords(d, "y2", "radial")})
            .attr("stroke-width", 2)
            .attr("transform", d => {return `rotate(${d.x? /* 90 */getLineRotationAngle(d) - (d.x * 180/Math.PI) : 0})`})
            .attr("stroke", function(d) {return atrributeColortoNode(d, "stroke") });


    }

    function regularTreeDiagram(root, svg, height, width, seqInfo){

        const treemap = d3.tree().size([height, width]);

     
        let nodes = treemap(root);
        //console.log(nodes.links());

        var treeLinks = svg.selectAll('line.link')
          .data(nodes.links())
          .enter()
          .append('line')
          .classed('link', true)
          .attr("x1", d => d.source.x)
          .attr("y1", d => d.source.y)
          .attr("x2", d => d.target.x)
          .attr("y2", d => d.target.y)
          .on("click", d => {setBranchDetailsView(d); 
                             //console.log("understand the branch", d);
                             d3.selectAll(".click-circle-" + view_name.split(" ").join("")).remove();
                             d3.selectAll(".branchSelect-" + view_name.split(" ").join("")).style("stroke", d => {return atrributeColortoBranch(d.target)}).style("stroke-dasharray", ("")); 
                             d3.select(d.target).classed("branchSelect-" + view_name.split(" ").join(""), true); 
                             d.target.style.stroke = "rgb(0,0,0)";
                             d.target.style.strokeDasharray ="5, 3"})
          .style("cursor", "pointer")
          .text(function(d) {
            //console.log(d);
            if(d.target.data.mutations){
                //console.log(d.target.data.mutations.length);
                return d.target.data.mutations.length;
            }
            else
                return "";
        });


        treeLinks  
          .style("stroke", d => {return atrributeColortoBranch(d.target);})
          .attr("stroke-width", d => {if(view_options[0] && d.target.data.mutations){ return d.target.data.mutations.length;}/*  return d.data.mutations.length; */ else return 2;})
          .attr("transform", `translate(0, 50)`)

            if(view_options[1]){
                console.log("append text");
                svg.selectAll('text.link')
                .data(nodes.links())
                .enter().append("text")
                .style("font", "normal 20px Arial")
                .attr("transform", function(d) {
                    return "translate(" +
                        (d.target.x) + "," + 
                        (d.target.y) + ")";
                })   
                .text(function(d) {
                    console.log(d);
                    if(d.target.data.mutations){
                        console.log(d.target.data.mutations.length);
                        return d.target.data.mutations.length;
                    }
                    else
                        return "";
                });
            }


        var treeNodes = svg.selectAll('g.node')
          .data(nodes.descendants())
          .enter()
          .append('g')
          .classed('node', true)
          .attr("transform", `translate(0, 50)`)
          .style("cursor", "pointer");        
          


        treeNodes.append("path")
          .classed('the-node solid', true)
          .style("fill", function(d) {return atrributeColortoNode(d, "fill") })
          .style("stroke", function(d) {return atrributeColortoNode(d, "stroke") })
          .style("stroke-width", 2)
          .attr("d", d3.symbol()
            .size(d => {if(d.data.name==='') return 5*15; else {if(view_options[2] && !d.data.name.includes('GERM')){return calculateNodeSize(d.data.name)*22;} else return 10*22;}})
            .type(function(d) { if
            (d.data.name.includes("GERM")) { return d3.symbolSquare; } 
            else { return d3.symbolCircle;}
            })
          )
          .attr("transform", d=> {return "translate(" + d.x + "," + d.y + ")"})
          .on("click", d => {setNodeDetailsView(d);
                             d3.selectAll(".branchSelect-" + view_name.split(" ").join("")).style("stroke", d => {return atrributeColortoBranch(d.target)}).style("stroke-dasharray", ("")); 
                             d3.selectAll(".click-circle-" + view_name.split(" ").join("")).remove();
                             treeNodes.append("circle")
                                            .attr("r", 15)
                                            .style("stroke", "#000")
                                            .style("fill", "none")
                                            .attr('class', d => {return "click-circle-"+view_name.split(" ").join("")})
                                            .attr("transform", "translate(" + (d.target.__data__.x) + "," + (d.target.__data__.y) + ")")})



          treeNodes.append('line')
          .attr("x1", d => {return getLineCircleCoords(d, "x1", "regular")})
          .attr("y1", d => {return getLineCircleCoords(d, "y1", "regular")})
          .attr("x2", d => {return getLineCircleCoords(d, "x2", "regular")})
          .attr("y2", d => {return getLineCircleCoords(d, "y2", "regular")})
          .attr("stroke-width", 2)
          .attr("stroke", function(d) {return atrributeColortoNode(d, "stroke") });



          if(view_options[3]){
            const metadata = view_options[3];
            //console.log(metadata);
            
            treeNodes.append("path")
                   .style("fill", "#000")
                   .style("opacity", d => {if(hasMetadata(d.data.name, "cross")) return 1; else return 0})
                   .attr("d", d3.symbol()
                        .size(50)
                        .type(d3.symbolCross))
                   .attr("transform", d=> {return "translate(" + (d.x + 12) + "," + (d.y+10) + ")"});

            treeNodes.append("path")
                   .style("fill", "#000")
                   .style("opacity",d => { if(hasMetadata(d.data.name, "diamond")) return 1; else return 0})
                   .attr("d", d3.symbol()
                        .size(50)
                        .type(d3.symbolDiamond))
                   .attr("transform", d=> {return "translate(" + (d.x + 12*2) + "," + (d.y+10) + ")"});

            treeNodes.append("path")
                   .style("fill", "#000")
                   .style("opacity", d => { if(hasMetadata(d.data.name, "star")) return 1; else return 0})
                   .attr("d", d3.symbol()
                        .size(50)
                        .type(d3.symbolStar))
                   .attr("transform", d=> {return "translate(" + (d.x + 12*3) + "," + (d.y+10) + ")"});

            treeNodes.append("path")
                   .style("fill", "#000")
                   .style("opacity", d => {if(hasMetadata(d.data.name, "triangle")) return 1; else return 0} )
                   .attr("d", d3.symbol()
                        .size(50)
                        .type(d3.symbolTriangle))
                   .attr("transform", d=> {return "translate(" + (d.x + 12*4) + "," + (d.y+10) + ")"});

            treeNodes.append("path")
                   .style("fill", "#000")
                   .style("opacity", d => {if(hasMetadata(d.data.name, "wye")) return 1; else return 0})
                   .attr("d", d3.symbol()
                        .size(50)
                        .type(d3.symbolWye))
                   .attr("transform", d=> {return "translate(" + (d.x + 12*5) + "," + (d.y+10) + ")"});
          }
          
        
        
        //console.log(nodes);
        return nodes;


    }

const hasMetadata = (nodeName, symbol) => {

    let metadataNode;
    let hasMetadata = false;

    sequencesInfo.forEach(s => {if(s.name===convertNodeName(nodeName)){metadataNode = s.metadata}});

    if(metadataNode){
        view_options[4].forEach((value, key) => { 
            if (key==symbol) {
                //console.log('inside if');
                metadataNode.forEach((a) => {
                    if(a[0] === value.split(' ')[0] && a[1] === value.split(' ')[1]) hasMetadata = true; 
                })
            };
        });
    }

    return hasMetadata;
}


const convertNodeName = nodeName => `${nodeName.split('-').splice(0, 3).join(':')}-${nodeName.split('-').splice(3, 5).join(':')}`;




function getMutationsperBranch(node){

    /* console.log(node);
    console.log(node.data.mutations); */

    let commonMutations = [];
    let childrenMutations = [];

    if(node.parent && node.data.mutations){//if current node has known mutations

        //console.log(node.parent.children.length)
        

        //Compare with siblings
        if(node.parent.children.length>1){

            //console.log("got in more than one child", node.parent.children);

            node.parent.children.forEach(child => {if(child.data.mutations) childrenMutations.push(child.data.mutations)}); //put the mutations of the current node sibilings and the mutations of current node in an array, if they are known

            //console.log("All children mutations", childrenMutations);


            if(childrenMutations.length === node.parent.children.length){ //in case one of the children doesn't have mutations
                const size = childrenMutations.length;
                const auxMutationsMap = new Map();
                
                childrenMutations.forEach(arr => {
                    arr.forEach(entry => {
                    if (!auxMutationsMap.has(entry)) {
                        auxMutationsMap.set(entry, 1);
                    } else {
                        let timesSeen = auxMutationsMap.get(entry);
                        auxMutationsMap.set(entry, ++timesSeen);
                    }
                    });
                });

                auxMutationsMap.forEach((count, key) => {
                    if (count === size) {
                        commonMutations.push(key);
                    }
                });
            }


            //console.log("Common mutations between children", commonMutations);
            
        }
        else if(node.parent.children.length===1 && node.parent.data.name===""){
            //console.log("got in only one child");

            const children = node.children
            
            const newparent = node.parent.parent
            
            //find child node in newparent that used to be parent and remove it first
            newparent.children.forEach((child, index) => {if(child==node.parent){
                                                        newparent.children.splice(index,1);
                                                    } 
                                                }
                                        );

            node.parent = node;
            node.children = children;
            node.parent.parent = newparent;
            node.parent.children.push(node);


        }


        if(commonMutations.length>0){
            //console.log("Common mutations exist", commonMutations);
            //remove common mutations from all sibilings
            node.parent.children.forEach(child => {/* console.log("Child I'm deleting common mutations from", child); */ const newMutations = child.data.mutations.filter(i => !commonMutations.includes(i)); //remove the common mutations from current node
                                        child.data.mutations = newMutations;});

            //compare and/or attribute common mutations to parent
            if(!node.parent.data.mutations){
                node.parent.data.mutations = commonMutations;
            }
            else{
                let notInParent = [];
                let inParentNotCalculated = [];
                commonMutations.forEach(mutation => {if(!node.parent.data.mutations.includes(mutation)){notInParent.push(mutation)}}) 
                node.parent.data.mutations.forEach(mutation => {if(!commonMutations.includes(mutation)){inParentNotCalculated.push(mutation)}}) 
            } 
        }

        if(node.data.mutations.length===0){
            //console.log("node doesn't have mutations in relation to parent", node);
    
            //First add node with no mutations to collapsed sequences of parent
            if(node.data.name){

                if(node.parent.data.name){
                    let translated_node = convertNodeName(node.parent.data.name);
    
                    let sequenceObject = sequencesInfo.find(seq => {
                        return seq.name === translated_node
                    })

                    //console.log("Node info", sequenceObject);
        
                    if(!sequenceObject.collapsed_sequences.includes(convertNodeName(node.data.name)))
                        sequenceObject.collapsed_sequences.push(convertNodeName(node.data.name));
        
                    //console.log(sequencesInfo);

                    //Then delete node, and put children on parent
                    //console.log("remove node", node);
                    if(node.children){
                        //console.log("THIS NODE HAD CHILDREN")
                        passChildrenToParent(node, node.parent);
                    }
                    const index = node.parent.children.indexOf(node);
                    node.parent.children.splice(index,1);                   
                    
                }
                else{
                    makeNodeParent(node, node.parent);
                    //console.log("Parent after altertions", node.parent);

                }    
            }
            else{
                /* console.log("THIS NODE DOESN'T HAVE A NAME");
                console.log(node.children); */
                passChildrenToParent(node, node.parent);
            }
            
            if(node.parent.children && node.parent.children.length===0){
                delete node.parent.children;
                //console.log("Removed all children", node.parent);
            }
        }
    } 
}

const passChildrenToParent = (node, parent) => {

    //console.log("node parent", parent);

    const newchildren = parent.children.slice();
    newchildren.splice(parent.children.indexOf(node), 1);

    //console.log(newchildren);

    if(node.children){
        node.children = node.children.concat(newchildren);
    }
    else{
        node.children = newchildren;
    } 

    parent.children = node.children;

    node.children.forEach(child => {child.parent = parent;})

}

const reverseLevelOrderTraversal = node => {

    const maxDepth = getNodeMaxDepth(node);

    for(let i= maxDepth+1; i>0; i--){
        rlot(node, i);
    }

}

const rlot = (node, level) => {

    if (node == null) return;
    if (level == 1){
        //console.log("node being visited", node);
        if(node.parent!==null) getMutationsperBranch(node);
    }
    else if (level > 1) {
       
       if(node.children){

            Object.keys(node.children).reverse().forEach(child => rlot(node.children[child], level - 1));

       }

        
    }
}

const getNodeMaxDepth = node => {

   const leaves = node.leaves();

   return Math.max(...leaves.map(leaf => leaf.depth));
}

const removeZeroDistance = (node, parent) => {

    if(node.children){
        node.children.forEach(c => removeZeroDistance(c, node, newick));
    }

    if(node.distance<0.001){

        makeNodeParent(node,parent);

    }
    
}

const makeNodeParent = (node, parent) =>{

        //console.log("NODE BECOMING PARENT", node);

        const newchildren = parent.children.slice();
        newchildren.splice(parent.children.indexOf(node), 1);

        if(node.children){
            node.children = node.children.concat(newchildren);
        }
        else{
            node.children = newchildren;
        } 
        
        //console.log("new children nodes", node.children);
        parent.children = node.children;



        if(node.data){
            /* console.log("this node", node);
            console.log("the parent", parent); */
            
            parent.data.name = node.data.name; 

            node.children.forEach(child => {child.parent = parent;})

        }
        else{

            parent.name = node.name; 
    
            if(node.mutations) parent.mutations = node.mutations;
        }
  
}

function treeDiagram(data, height, width, seqInfo){  

    let newick = parseNewick(data, seqInfo);
    console.log(newick);

    removeZeroDistance(newick, null);
    console.log(newick);

    let root = d3.hierarchy(newick, d => d.children)
                 /* .sum(d => { d.children ? 0 : 1}) */
                 .sum(d => d.value)
                 .sort((a, b) => d3.descending(getNodeMaxDepth(a),getNodeMaxDepth(b)) || (a.value - b.value) || d3.ascending(a.data.distance, b.data.distance) );

    let nodeId = 0;
    root.each(d => {d.id = nodeId; nodeId++});

    //console.log(getNodeMaxDepth(root));

    reverseLevelOrderTraversal(root);
    //console.log("After visiting all nodes for mutations", root);

    /* root.eachAfter(d => getMutationsperBranch(d)); */

    //console.log("After changing mutations", root);

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    let svg = svgEl.append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 " + (width+100) + " " + (height+100))
        .classed("svg-content", true);

     if(tree_type === "radial")
         radialTreeDiagram(root, svg, height, width, seqInfo);

     if(tree_type === "regular"){
         regularTreeDiagram(root, svg, height, width, seqInfo);
     }
         


   }

useEffect(() => {

    //const metadata = view_options[3];
    treeDiagram(newick,  1000, 1000, sequencesInfo);

}, [view_options]);  

if (!sequencesInfo) {
    return <div>Tree Loading...</div>;
  }
if(!highlightDetails){
    return (
        <> 
            <div ref={svgRef} className="svg-container"></div>
    
            <div class= "highlighted_details">
                <Box className='viewDetails' sx={{ border: 1, padding: 2}}>
                            <p>Details</p>
                            <p>No node or branch is selected</p>
                </Box>
            </div>
        </>
        );
}

return (
    <> 
        <div ref={svgRef} className="svg-container"></div>

        <div class= "highlighted_details">
            <Box className='viewDetails' sx={{ border: 1, padding: 2}}>
                <p>Details</p>
                {
                    highlightDetails.name && <><p className='subTitle'>Name: </p><p className='infoText'> {highlightDetails.name}</p>
                    <p className='subTitle'>Size: </p><p className='infoText'>{highlightDetails.size}</p>
                    <p className='subTitle'>Collapsed Sequences: </p>
                    {highlightDetails.collapsed_sequences?.map(j => {
                          return <p className='infoText'>{j}</p>
                    })}
                    <p className='subTitle'>Metadata: </p>
                    {view_options[3].length===0 && (<p className='infoText'>No selected metadata</p>)}
                    {view_options[3].length>0 && highlightDetails.metadata?.map(j => { 
                        return <p className='infoText'>{j[0] + " " + j[1]}</p>
                    })}</>
                }
                {
                    highlightDetails.mutations && <><span><p className='subTitle'>From: </p><p className='infoText'>{highlightDetails.source}</p></span>
                    <p className='subTitle'>To: </p><p className='infoText'>{highlightDetails.target}</p>
                    <p className='subTitle'>Number of Mutations: </p><p className='infoText'>{highlightDetails.numberMutations}</p>
                    <p className='subTitle'>Mutations: </p>
                    <div className='mutations'>{highlightDetails.mutations?.map(j => {
                          return <p className='infoText'>{j}</p>
                    })}</div>
                    </>
                }
                
            </Box>
        </div>
        </>
    );


};

export default Tree;