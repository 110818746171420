import React, { useEffect, useState } from 'react';
import * as d3 from "d3";

import './View.css';

import Draggable from "react-draggable";

import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Tree from './Tree';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const View = ({ view_name, clone_id, mode, layout, selectedTimepoints, possibleTimepoints, showView, metadataSelected, metadataInfo, sequencesInfo, newicks}) => {

    console.log("Selected Metadata", metadataSelected);
    const allShapes = ["cross","diamond","star","triangle","wye"];

    const[mutations, setMutations] = useState(false);
    const[mutationsNumber, setMutationsNumber] = useState(false);
    const[sequenceSize, setSequenceSize] = useState(false);
    const[shapesMetadata, setShapesMetadata] = useState(new Map());
    const[shapes, setShapes] = useState([]);

/*     const cross = d3.symbol().type(d3.symbolCross);
    const diamond = d3.symbol().type(d3.symbolDiamond);
    const star = d3.symbol().type(d3.symbolStar);
    const triangle = d3.symbol().type(d3.symbolTriangle);
    const wye = d3.symbol().type(d3.symbolWye); */


    const allTimepoints = possibleTimepoints;
    const timepoints = selectedTimepoints;

    //let metadata = ["Tissue", "Spike-Pos"]
    console.log(newicks);
  
    const handleInputChange = e => {
        const target = e.target;
        let value = target.value;

        if(target.name === "sequence_size"){

            if(sequenceSize === true) setSequenceSize(false);
            else if (sequenceSize === false) setSequenceSize(true);
  
        }
        if(target.name === "mutations"){

            if(mutations === true) setMutations(false);
            else if (mutations === false) setMutations(true);
  
        } 
        if(target.name === "mutations_number"){

            if(mutationsNumber === true) setMutationsNumber(false);
            else if (mutationsNumber === false) setMutationsNumber(true);
  
        } 
        if(target.name.includes("shapes")){

            const shape = value;
            const metadata = target.name.split(":")[1];

            let oldShape;
            shapesMetadata.forEach((value, key) => {if(value===metadata) oldShape=key})


            if(oldShape){
                setShapesMetadata(shapesMetadata => new Map([...shapesMetadata].filter(s => s[0] !== oldShape)));
                setShapes(shapes => shapes.filter(s => s !== oldShape));
            }

            if(value){
                setShapesMetadata(shapesMetadata => new Map(shapesMetadata.set(shape, metadata)));
                setShapes(shapes => [...shapes, shape]);
            }
            
  
        } 
    }

    const getDisplayShapeValue = (m, j) => {
        const s = [...shapesMetadata].find(sm => sm[1] === m +" " +j);
        const a = s ? s[0] : '';
        return a;
    }

    const all_newick = newicks.find(n => n.time_point === "all");
    console.log(all_newick)

    return (
        <Draggable>
            <div className="treeView">
                <div className='viewInfoWrapper'>
                    <h2>{view_name}</h2>
                    <p className='viewInfo'>Clone {clone_id}</p>
                    <p className='viewInfo infoTps'>Timepoints {timepoints?.map(t => {return <p className='viewInfo'>{t} </p>})}</p>
                </div> 
                {mode === "Integrated" && <Tree newick_Trees={newicks} newick={all_newick? all_newick.newick_tree : newicks[0].newick_tree} tree_type={layout} view_options={[mutations,mutationsNumber,sequenceSize,metadataSelected,shapesMetadata]} selectedTimepoints={timepoints} possibleTimepoints={allTimepoints} view_name={view_name} sequencesInfo={sequencesInfo}></Tree>}
                {mode === "Side-by-Side" && timepoints.map((t) => {return <Tree newick_Trees={newicks} newick={newicks.find(n => n.time_point === t).newick_tree} tree_type={layout} view_options={[mutations,mutationsNumber,sequenceSize,metadataSelected,shapesMetadata]} selectedTimepoints={t} possibleTimepoints={allTimepoints} view_name={view_name} sequencesInfo={sequencesInfo}></Tree>} )}
                <div className='bottomDetails'>
                    <Box className='viewOptions'>
                        <FormLabel component="legend">View Options</FormLabel>
                            <FormControlLabel control={<Checkbox name="mutations_number" onChange={handleInputChange}/>} label="Show Number of Mutations" />
                            <FormControlLabel control={<Checkbox name="mutations" onChange={handleInputChange}/>} label="Show Mutations Visually" />
                            <FormControlLabel control={<Checkbox name="sequence_size" onChange={handleInputChange}/>} label="Show size of sequence copies" />
                            {metadataSelected.map((m) => {
                                return <div>
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            >
                                            <Typography>{m}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                            <div className='metadataSelects'> 
                                            {metadataInfo[m].map((j) => {
                                                return <FormControlLabel control={<FormControl fullWidth>
                                                    <InputLabel>Shape</InputLabel>
                                                    <Select
                                                    name={"shapes:" + m + " " + j}
                                                    value={getDisplayShapeValue(m, j)}
                                                    label={j}
                                                    onChange={handleInputChange}
                                                    >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {allShapes.map(s => {return <MenuItem disabled={Array.from(shapes).includes(s)} value={s} label={j}>{s}</MenuItem>})}
                                                    </Select>
                                                </FormControl>} label={j} />
                                            })}
                                            </div>  
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            })}
                    </Box>
                </div>
            </div>
           
        </Draggable>      
    )
        

};

export default View;