import React, { useState, useEffect } from 'react';
import View from './View';
import { TextField, Box, Button, RadioGroup, Radio, Checkbox, FormGroup, FormLabel, FormControlLabel } from '@mui/material';
import { mode } from 'd3';
import './TreeViews.css';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const TreeViews = ({clone_id, newickTrees, sequencesInfo}) => {

    const host = window.location.origin;


    const [views, setViews] = useState([]);
    const [viewName, setViewName] = useState("");
    const [timepoints, setTimepoints] = useState([]);
    const [mode, setMode] = useState("");
    const [layout, setLayout] = useState("");
    const [addBtnClicked, setAddBtnClicked] = useState(false);
    const [show, setShow] = useState(true);
    const [metadata, setMetadata] = useState({});
    const [metadataName, setMetadataName] = useState([]);
    // const [sequencesInfo, setSequencesInfo] = useState([]);
    // const [newickTrees, setnewickTrees] = useState([]);
    const [previousNewicks, setPreviousNewicks] = useState([]);
    const [previousClone, setPreviousClone] = useState();
    //const [correctCloneSeqs, setCorrectCloneSeqs] = useState(false);
    const [source, setSource] = useState();

    console.log(clone_id);
    console.log(newickTrees);


    const changeBtnText = (show) => {
        if(show) return "Hide";
        else return "Show";
    }
     
    let time_points = [1, 2, 4];


    const handleAddView = e => {
        e.preventDefault();  

        setAddBtnClicked(false);

        console.log(timepoints);

        let newView = {
            viewName: viewName,
            timepoints: timepoints,
            viewMode: mode,
            layout: layout,
            show: show,
            metadata: metadataName,
            button: "Hide"
        }

        console.log(newView);

        setViews(prevViews => [...prevViews, newView]);
        setTimepoints([]);

        console.log(views);
        console.log(timepoints);

    }

    const handleChange = e => {
        const target = e.target;
        let value = target.value;

        switch(target.name){
            case "viewName":
                setViewName(value);
                break;
            case "timepoints":
                if(target.checked){
                    setTimepoints(timepoints => [...timepoints,value]);
                }
                else if(!target.checked){
                    let aux_timepoints_array = timepoints;
                    let index_timepoint = timepoints.indexOf(value);
                    aux_timepoints_array.splice(index_timepoint, 1);
                    setTimepoints(aux_timepoints_array);
                }
                //setTimepoints(timepoints.sort());
                break;
            case "mode":
                setMode(value);
                break;
            case "layout":
                setLayout(value);
                break;
            case "metadata":
                setMetadataName(
                    // On autofill we get a stringified value.
                    typeof value === 'string' ? value.split(',') : value,
                );
                console.log(metadataName);
                break;
        }

    }

  

    useEffect(() => {

            setViews([]);

            axios.get(host + "/metadata/")
            .then(res => {
                setMetadata(res.data);
            })
            .catch(function (error) {
            console.log(error);
            })

    },[clone_id]);

 
/* if(sequencesInfo.length===0 || !correctCloneSeqs){

   return  (<p>Please hold while fetching sequences and their metadata...</p>)
} */
// else{
    time_points = [];
    newickTrees.forEach(n => {if(n.time_point!=="all") time_points.push(n.time_point);});
    time_points.sort();
    if(!addBtnClicked && metadata){
        return (
            <>
            <div className='views'>
                <Button className='newView itemViewOrganizer' sx={{borderRadius: '20px'}} onClick={() => {setAddBtnClicked(true);}}>New View +</Button>

                <div className='viewOrganizer'>
    
                        {views?.map(view => {
                                return <>
                                <Box className='itemViewOrganizer' sx={{ paddingLeft: 3, paddingRight: 3}}>
                                    <p>{view.viewName}</p> 
                                    <div>
                                     <Button className='buttonView' onClick={() => {setViews(current =>current.map(v => {if (v.viewName === view.viewName) {return {...v, show: !view.show, button: changeBtnText(!view.show)};}return v;}));}}>{view.button}</Button> 
                                     <Button className='buttonView' onClick={() => {setViews(views.filter(v => v.viewName !== view.viewName));}}>Delete</Button>
                                    </div>
                                </Box>
                                </>
                        })}    
                </div>
            </div>
        
            <div className='viewsContainer'>
                {views?.map(view => {if(view.show){return <View id={view.viewName} clone_id={clone_id} view_name={view.viewName} mode={view.viewMode} layout={view.layout} selectedTimepoints={view.timepoints} possibleTimepoints={time_points} showView={view.show} metadataSelected={view.metadata} metadataInfo={metadata} sequencesInfo={sequencesInfo} newicks={newickTrees}></View>}})   } 
            </div>
            </>
        )
    }
    if(addBtnClicked && metadata){
        return (
            <>
            <div>
                <form className='addView'>
                    
                    <FormGroup className="form-group">
                    
                        <Box className="addViewForm" sx={{ padding: 2, /* border: 2, borderRadius: '15px', borderColor: 'primary.dark', width: 1/4, margin: '5%' */}}>
                            <Button className='buttonViewClose' size='small' variant='contained' onClick={() => {setAddBtnClicked(false);}}>X</Button>
                            <div className='viewFormContainer'>
                            <TextField
                                required
                                id="view-name"
                                label="View Name"
                                defaultValue=""
                                name="viewName"
                                onChange={handleChange}
                                sx={{marginTop: '1em'}}
                            />
                            <Box sx={{ gridColumn: '2', padding: 2}}>
                                <FormLabel component="legend">Time Point(s)</FormLabel>
                                {time_points?.map(j => {
                                return <FormControlLabel control={<Checkbox name="timepoints" id={"inlineCheckbox" + {j}} value={j} onChange={handleChange}/>} label={j} />
                                })}
                            </Box>
                            <Box sx={{ gridColumn: '2', padding: 2, alignItems: 'center'}}>
                                <FormLabel component="legend">Mode</FormLabel>
                                <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                >
                                    <Box>
                                        <FormControlLabel control={<Radio name="mode" value="Side-by-Side" onChange={handleChange}/>} label="Side-by-Side" />
                                        <FormControlLabel control={<Radio name="mode" value="Integrated" onChange={handleChange}/>} label="Integrated" />
                                    </Box>
                                    
                                </RadioGroup>
                            </Box>
                            <Box sx={{ gridColumn: '2', padding: 2, alignItems: 'center'}}>
                                <FormLabel component="legend">Tree Layout</FormLabel>
                                <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                >
                                    <Box>
                                        <FormControlLabel control={<Radio name="layout" value="regular" onChange={handleChange}/>} label="Regular" />
                                        <FormControlLabel control={<Radio name="layout" value="radial" onChange={handleChange}/>} label="Radial" />
                                    </Box>
                                    
                                </RadioGroup>
                            </Box>
                            <Box sx={{ gridColumn: '2', padding: 2, alignItems: 'center'}}>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-chip-label">Metadata</InputLabel>
                                    <Select
                                    name='metadata'
                                    multiple
                                    value={metadataName}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Metadata" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                        </Box>
                                    )}
                                    >
                                    {Object.keys(metadata)?.map((name) => (
                                        <MenuItem
                                        key={name}
                                        value={name}
                                        /* style={getStyles(name, metadataName, theme)} */
                                        >
                                        {name}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            </div>
                            <Button className='buttonViewAdd' size="large" variant="contained" type="submit" onClick={e => handleAddView(e)}>Add View</Button>
                        </Box>
                    </FormGroup>
                </form>
        </div>
        <div className='viewsContainer'>
            {views?.map(view => {if(view.show){return <View id={view.viewName} clone_id={clone_id} view_name={view.viewName} mode={view.viewMode} layout={view.layout} selectedTimepoints={view.timepoints} possibleTimepoints={time_points} showView={view.show} metadataSelected={view.metadata} metadataInfo={metadata} sequencesInfo={sequencesInfo} newicks={newickTrees}></View>}})   } 
        </div>
            </>
        )
    }   
//}   

    
        

};

export default TreeViews;