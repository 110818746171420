import * as React from "react";
import NatureIcon from '@mui/icons-material/Nature';


/* const dataProvider = drfProvider("localhost:8000");

const App = () => <Admin dataProvider={dataProvider} />;

export default App; */

import './App.css';
import FilterForm from './components/Form.js'
import View from "./components/View";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>MultiTimeVis<NatureIcon sx={{ fontSize: 35 }}/></p>
      </header>
      <div className="appBody">
        <FilterForm></FilterForm>
      </div>
    </div>
  );
}

export default App;
